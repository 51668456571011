import { useState, useEffect } from 'react'
import {useDropzone}  from 'react-dropzone'
import { Buffer } from "buffer"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';

import axios from 'axios'

import ReactCardFlip from 'react-card-flip'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Box,
  Divider,
  Alert,
  Tooltip,
  Switch,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Link,
} from '@mui/material'

import InfoIcon from '@mui/icons-material/Info';

const Hash = require('ipfs-only-hash')

const defaultNft = {
  assetName: false,
  metadata: {
    title: false,
    image: false,
    imageType: false,    
  },
  customMetadata: [
    {
      placeholderKey: "description",
      placeholderValue: "my own cnft",
      value: "",
      key: "description",
    },
    {
      placeholderKey: "e.g. author or other",
      placeholderValue: "mr. cardano",
      value: "",
      key: "",
    },
  ],
  localImage: false,
  
}

function SingleNftMaker(props) {

  const [isEditable, setIsEditable] = useState(true)
  const [preview, setPreview] = useState(false)
  const [nft, setNft] = useState();
  const [nftDraft, setNftDraft] = useState(props.nft||defaultNft)
  const [imageFile, setImageFile] = useState()

  const addNft = (obj) => {
    setNftDraft({
      ...nftDraft,
      ...obj
    })
  }

  const addNftMetadata = (obj) => {
    setNftDraft({
      ...nftDraft,
      metadata: {
        ...nftDraft.metadata,
        ...obj
      },
    })
  }

  const setNftCustomMetadata = (list) => {
    setNftDraft({
      ...nftDraft,
      customMetadata: list
    })
  }

  const handlerActivate = ({url}) => {
    
    setIsEditable(false)
    setPreview(true)

    const uploadFile = async () => {
      
        var body = new FormData();
        body.append('file', imageFile )
        const axiosResponse = await axios({
          method: 'PUT', 
          url, 
          data: imageFile,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log({axiosResponse})
      
      
    }
    uploadFile();

  }

  const handlerCancel = () => {
    //const
    setIsEditable(true)
    setPreview(false)
  }

  const togglePreview = (event) => {
    if(!isEditable) setPreview(true)
    setPreview(event.target.checked)
  }

  /*
  Flow:
  Visit page, see empty NFT
  Add NFT Data



  */

  return (
    <>
      <Container>
        <Typography sx={{paddingTop:4, paddingBottom:2}} variant="h2" color="secondary">Mint your own CNFT</Typography>
        <Typography variant="subheading" color="secondary">Upload an image, add metadata and publish your NFT.</Typography>
          <Grid container spacing={2} sx={{marginTop:1}}>
            <Grid item sm={12} md={6}>
              <Paper sx={{minHeight:'620px'}}>
                <Box p={2}>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography variant="h5">
                        1. Define NFT&nbsp;
                        <Tooltip title={<TooltipContent />}><InfoIcon /></Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item md={4} sx={{textAlign: 'right'}}>
                      Preview: <Switch disabled={!isEditable} onClick={togglePreview} value={preview} />
                    </Grid>
                  </Grid>
                  <Divider sx={{marginBottom:2}} />
                  <Box sx={{textAlign: 'center'}}>  
                    <Box><EditTitle addNft={addNft} addNftMetadata={addNftMetadata} preview={preview} isEditable={isEditable} nftDraft={nftDraft} /></Box>
                    <Box><EditImage setImageFile={setImageFile} addNftMetadata={addNftMetadata} preview={preview} isEditable={isEditable} nftDraft={nftDraft} /></Box>
                    <Box><EditAttributes setNftCustomMetadata={setNftCustomMetadata} preview={preview} isEditable={isEditable} nftDraft={nftDraft} /></Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
              <Paper sx={{minHeight:'620px'}}>
                <Box p={2}>
                  <Typography variant="h5">2. Create NFT</Typography>
                  <Divider sx={{marginBottom:2}} />
                  <Box pb={2}>
                    
                    <ActivateProject nftDraft={nftDraft} onActivate={handlerActivate} onCancel={handlerCancel} />
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {false &&<pre>{JSON.stringify(nftDraft,null,2)}</pre>}
            <Grid item md={12}>
              <Divider sx={{paddingBottom: 18}} />
            </Grid>
        </Grid>
    </Container>
  </>
  );
}

export default SingleNftMaker;
 

const TooltipContent = () => {
  return (
    <div>
      <Typography variant="h6">How to</Typography>
      <HelpScreen />
    </div>
  )
}

const EditTitle = ({preview, nftDraft, addNft, addNftMetadata}) => {

  const onChange = (event) => {
    addNftMetadata({title:event.target.value})
  }


  return (
    <div>
      { preview && <Typography variant="h4">{nftDraft.metadata.title}</Typography>}
      { ! preview && <TextField fullWidth value={nftDraft.metadata.title||""} onChange={onChange} placeholder={"Your NFT's title..."} />}
      
    </div>
  )
}

const EditImage = ( { nftDraft, addNftMetadata, isEditable, preview, setImageFile } ) => {

  const { image } = nftDraft.metadata;

  const [hash, setHash] = useState()
  const [file, setFile] = useState()
  
  useEffect(()=>{
    if(hash){
      addNftMetadata({
        image: 'ipfs://' + hash,
        imageType: file.type
      })
    }
  },[hash])

  useEffect( () => {
    const getHash = async () => {
      var reader = new FileReader();
      reader.onload = function (){
        const buf = Buffer.from(reader.result)
        Hash.of(buf).then(hash=>setHash(hash))
      };
      reader.readAsArrayBuffer(file);
      
    }
    const uploadToS3 = async () => {
      // s3.put(...)
    }
    getHash()
    uploadToS3()
  },[file])

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  };
  
  const thumb = {
    display: 'inline-flex',
    maxWidth: 400,
    maxHeight: 400,
    
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  };
  
  const img = {
    display: 'block',
    width: '100%',
    height: '100%'
  };

  const dropzone = {
    borderRadius: 2,
    
    //minHeight: '250px',
  }
  
  
  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    disabled: !isEditable,
    onDrop: acceptedFiles => {
      console.log(acceptedFiles[0])
      const file = acceptedFiles[0];
      
      setImageFile(file);

      setFile(Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));

      
      
    }
  });

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    if( file ) URL.revokeObjectURL(file.preview);
  }, [file]);

  return (
    <Box sx={{paddingTop:2, paddingBottom: 2}}>
    <section className="container">
      <div style={dropzone} {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {(! file && ! preview) && <Box sx={{border:'dashed 1px darkgrey', padding: 8}}><p>Drag 'n' drop an image file here, or click to select files.</p></Box>}
        <aside style={thumbsContainer}>
          {file && 
            <div style={thumb} key={file.name}>
              <div style={thumbInner}>
                <img
                  src={file.preview}
                  style={img}
                  alt=""
                />
              </div>
            </div>
          }
        </aside>
      </div>
      
    </section>
    </Box>
  );
}

const EditAttributes = ({isEditable, preview, nftDraft, setNftCustomMetadata}) => {

  const edit = true;

  const onChangeKey = (i, event) => {
    const newList = nftDraft.customMetadata;
    newList[i].key = event.target.value
    setNftCustomMetadata(newList)
  }

  const onChangeValue = (i, event) => {
    const newList = nftDraft.customMetadata;
    newList[i].value = event.target.value
    setNftCustomMetadata(newList)
  }

  const handleAdd = () => {
    setNftCustomMetadata([...nftDraft.customMetadata, {value:"", key:"", placeholderValue:"some value", placeholderKey:"some attribute title"}])
  }

  return (
    <div>
      
      { nftDraft.customMetadata.map((attribute,i) => {
        return <div>
          {preview === false && <Grid container spacing={1} sx={{paddingBottom:4, '@media screen and (min-width: 600px)': {paddingBottom: 2}}}>
            <Grid item xs={12} sm={4}>
              <TextField label="key" fullWidth onChange={onChangeKey.bind(this, i)} size="small" value={attribute.key} placeholder={attribute.placeholderKey} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField label="value" fullWidth size="small" onChange={onChangeValue.bind(this, i)} value={attribute.value} placeholder={attribute.placeholderValue} />
            </Grid>
            </Grid>}
          {preview && <div>
            {attribute.value && <>
              <Typography variant="h6">{attribute.key}</Typography>
              <Typography>{attribute.value}</Typography>
            </>}
          </div>
          }
        </div>
      })}
      { isEditable && preview===false && <Box pt={2}><Button fullWidth color="primary" variant="outlined" onClick={handleAdd}>Add attribute</Button></Box>}
    </div>
  )
}


const ActivateProject = ({onActivate, onCancel, nftDraft}) => {

  const [isActivated, setIsActivated] = useState();
  const [validationFailed, setValidationFailed] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [price, setPrice] = useState(false);
  const [uuid, setUuid] = useState(false);
  const [uploadUrl, setUploadUrl] = useState(false);
  

  useEffect(()=> {
    let errors = []
    //if( ! nftDraft.assetName) errors.push('Asset name missing')
    if( ! nftDraft.metadata.title) errors.push('NFT title missing')
    if( ! nftDraft.metadata.image) errors.push('Image missing')
    setValidationErrors(errors)
    if(errors.length===0){
      setValidationFailed(false)
    }
  }, [nftDraft])

  useEffect(()=>{
    const invoke = async () => {
      
      const fullmetadata = JSON.stringify({
        ...nftDraft.customMetadata.reduce((a,v)=>({ ...a, [v.key]:v.value}),{}),
        ...nftDraft.metadata, 
      })
      
      const {data} = await axios.post('https://8lyvdu674d.execute-api.eu-central-1.amazonaws.com/default/nftInvokeFastCNFT?metadata='+fullmetadata+'&assetName='+nftDraft.assetName)
      setPrice(data.price)
      setUuid(data.uuid)
      setUploadUrl(data.url)
      onActivate({url:data.url})
    }
    if(isActivated){
      invoke();
    }else{
      setPrice(false)
      setUuid(false)
      setUploadUrl(false)
    }
  },[nftDraft, isActivated])

  const handlerActivate = () => {

    if(validationErrors.length){
      setValidationFailed(true)
    }else{
      setValidationFailed(false)
      setIsActivated(true)
    }
  }

  const handlerCancel = () => {
    onCancel()
    setIsActivated(false)
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div>Expired.</div>;
    } else {
      return <span>{minutes} minutes {seconds} seconds</span>;
    }
  };
  

  return (
    <div>
      <ReactCardFlip isFlipped={isActivated}>
        <div>
          <Box pt={2} pb={2}>
            <Alert severity="info" sx={{marginBottom:2}}>
              <Typography variant="body2">
                By clicking "mint NFT" you agree to our <PrivacyModal />
              </Typography>
            </Alert>
            <Button size="large" fullWidth onClick={handlerActivate} sx={{fontFamily:'Slackey'}} variant="contained" color="secondary">Mint NFT</Button>
            {validationFailed && validationErrors && <Box sx={{paddingTop: 2}}><Alert severity="warning">
              {validationErrors.map(e=><div>{e}</div>)}
            </Alert></Box>}
          </Box>
          <Typography variant="h6">Pricing</Typography>
          <PricingScreen price={price} />
          
        </div>
        <div>
          <Box pb={2} pt={2}>
            <Alert severity="info">
              <Typography variant="body2">By sending ADA you agree to our <TosModal /></Typography>
            </Alert>
          </Box>
          <Box pt={1}>
            <Typography>Send ADA:</Typography>
            <Box sx={{border:'dashed 1px #000', padding: 1, background: 'white'}}>
              <CopyToClipboard text={price/1000000}>
                <span>{(price/1000000)||"Please wait..."}</span>
              </CopyToClipboard>
            </Box>
            <Typography sx={{marginTop: 1}}>To address:</Typography>
            <Box sx={{maxWidth:'100%', wordWrap:'break-word', border:'dashed 1px #000', padding: 1, background: 'white'}}>addr1vyzucmz9l3vn2c6ndh8jrpwuu9r6st5wlkl9hcmq5cga53ck5kre9</Box>
            <Typography sx={{marginTop: 1}}>Remaining time:</Typography>
            <Box sx={{border:'dashed 1px #000', padding: 1, background: 'white'}}>
              <Countdown date={new Date(new Date().getTime() + 15*60000)} renderer={renderer} />
            </Box>
          </Box>
          <Box pb={2} pt={2}>
            <Alert severity="warning">
              <Typography variant="body2">Only send money from a wallet (eg. Yoroi, Nami, Daedalus). If you send from an Exchange, your NFT will be lost.</Typography>
              <Typography variant="body2">After the payment is made, it takes usually 3 Minutes for the NFT to be minted and sent to your wallet.</Typography>
            </Alert>
          </Box>
          <Box pt={1}>
            <Button fullWidth variant="outlined" color="warning" onClick={handlerCancel}>Cancel and edit NFT</Button>
          </Box>
        </div>
      </ReactCardFlip>
    </div>
  )
}

const HelpScreen = () => {
  return (
    <div>
      <Typography>1.) Add a title</Typography>
      <Typography>2.) Upload an image</Typography>
      <Typography>3.) Optional: Add some metadata</Typography>
      <Typography>4.) Click the "mint" button</Typography>
      <Typography>5.) Pay exactly the displayed amount from a supported wallet.</Typography>
    </div>
  )
}

const PricingScreen = () => {
  return (
    <Grid pb={2} container spacing={2}>
      <Grid item sm={7} md={12} lg={7}>
        <Box sx={{backgroundColor:'white'}}>
          <Table size="small">
            <TableBody>
              <TableRow >
                <TableCell sx={{paddingLeft:0}}>Service fee</TableCell>
                <TableCell>~1.4 ADA</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{paddingLeft:0}}>Network fees</TableCell>
                <TableCell>~0.3 ADA</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{paddingLeft:0}}>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                    Return amount&nbsp; <Tooltip title="Will be payed back to you together with the NFT"><InfoIcon fontSize="small" /></Tooltip>
                  </div>
                </TableCell>
                <TableCell>~1.8 ADA</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{paddingLeft:0}}><b>Payment</b></TableCell>
                <TableCell><b>~3.5 ADA</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{paddingLeft:0}}><b>Return</b></TableCell>
                <TableCell><b>~1.8 ADA + NFT</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid item sm={5} md={12} lg={5}>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="lightgrey">*The payment amount is around 3.5 ADA, you will receive 1.8 ADA + the NFT in return.</Typography>
      </Grid>
    </Grid>
  )
}


const PrivacyModal = () => {

  const [open, setOpen] = useState();

  const toggleOpen = () => setOpen(!open);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal open={open} onClose={toggleOpen}>
        <Paper sx={style}>
        <Typography variant="h5">Data privacy agreement</Typography>
        <Typography>All data provided by you to the NFT minting tool is meant to be public.</Typography>
        <Typography>When you click the "mint nft" button, we store all provided information on our servers and await payment. If the payment is successful we upload your data to an IPFS node for public distribution and mint your NFT on the public cardano blockchain.</Typography>
        <Typography>We use a third party tool tawk.to to provide chat functionality. Refer to https://www.tawk.to/legal/ to find out more about their data processing rules.</Typography>
        <Typography>We do not collect any other information about you.</Typography>
        </Paper>
      </Modal>
      <a href="#" onClick={toggleOpen}>data privacy agreement.</a>
    </>
  )
}


const TosModal = () => {

  const [open, setOpen] = useState();

  const toggleOpen = () => setOpen(!open);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal open={open} onClose={toggleOpen}>
        <Paper sx={style}>
          <Typography>We do not support burning of tokens. NFTs minted are non-destructible.</Typography>
          <Typography>Only use content that you have rights for. </Typography>
          <Typography>It is not allowed to mint content that contains explicit nudity, violence and hate symbols or speech. NFTs containing such content might not get minted and the payed amount will not be refunded.</Typography>
          <Typography>NFTs might use a shared minting policy.</Typography>
          <Typography>Follow your local laws.</Typography>
        </Paper>
      </Modal>
      <a href="#" onClick={toggleOpen}>Terms of Service.</a>
    </>
  )
}



