import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import {
  Typography,
  AppBar,
  Toolbar,
  Container,
  Box,
  Grid,
  IconButton,
} from '@mui/material'

import {
  Twitter as TwitterIcon
} from '@mui/icons-material'


import SingleNftMaker from './SingleNftMaker'
import '@fontsource/slackey/400.css';

function App() {

  const defaultTheme = createTheme()

  const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#181818',
        contrastText: '#43bc09',
      },
      secondary: {
        main: '#43bc09',
      },
      background: {
        default: '#222',
        paper: 'white'
      },
    },
    typography: {
      h1: {
        fontFamily: 'Slackey',
      },
      h3: {
        fontFamily: 'Slackey',
      },
      h4: {
        fontFamily: 'Slackey',
      },
      h5: {
        fontFamily: 'Slackey',
      },
      h6: {
        fontFamily: 'Slackey',
      },
      h2: {
        fontFamily: 'Slackey',
        '-webkit-text-stroke-width': '2px',
        '-webkit-text-stroke-color': 'white',
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '2.5rem'
        },
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '1.5rem'
        },
      },
    },
  });

  const qa1 = [
    {
      q: "What is an CNFT?",
      a: "I'm baby ut enamel pin fingerstache, hashtag single-origin coffee trust fund selfies freegan reprehenderit pug distillery neutra magna dreamcatcher leggings. Mollit yr ennui chambray fam cliche bespoke viral readymade craft beer bushwick."
    },
    {
      q: "What is Cardano?",
      a: "Sartorial brooklyn fashion axe occaecat, subway tile meggings gluten-free dolore etsy paleo viral dreamcatcher ethical freegan irure. Sint kinfolk readymade occaecat ea kale chips. Mlkshk cred labore DIY, af small batch ut tattooed lumbersexual street art locavore id authentic yuccie unicorn. XOXO in etsy, humblebrag twee biodiesel celiac tempor pabst minim kale chips."
    },
    ]

  const qa2 = [
    {
      q: "How do I receive the NFT?",
      a: "Cardigan iceland meditation small batch. Ethical trust fund banjo deep v. Pok pok ennui poutine culpa, bicycle rights kale chips iceland gluten-free la croix 8-bit."
    },
    {
      q: "How long does it take for the NFT to arrive?",
      a: "Cloud bread veniam lumbersexual, narwhal shabby chic fanny pack qui. Edison bulb ad magna, godard man braid hashtag craft beer in irure sriracha duis mumblecore bushwick et."
    },
    ]



  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppBar
          position="absolute"
          color="primary"
          elevation={0}
          sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
        >
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              fastCNFT
            </Typography>

            <IconButton color="secondary" href="https://twitter.com/fastcnft" target="_blank"> 
              <TwitterIcon/>
            </IconButton>
          </Toolbar>
          
        </AppBar>
        <Container maxWidth={false} sx={{backgroundColor:'background.default'}}>
          <SingleNftMaker />
        </Container>
        <Container>
          <Box pt={4} pb={8}>
            <Grid container>

              <Grid item md={12}>
                <Box p={2}>
                  <Typography variant="h4">Frequently asked questions</Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                {qa1.map(({q,a},i) => {
                  return (
                    <Box key={i} p={2}>
                      <Typography variant="h5">{q}</Typography>
                      <Typography>{a}</Typography>
                    </Box>
                  )
                })}
              </Grid>
              <Grid item md={6}>
                {qa2.map(({q,a},i) => {
                  return (
                    <Box key={i} p={2}>
                      <Typography variant="h5">{q}</Typography>
                      <Typography>{a}</Typography>
                    </Box>
                  )
                })}
                
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Container maxWidth={false} sx={{marginTop: 2}}>
          <Typography color="lightgrey" sx={{textAlign:'center'}}>
            Copyright 2021 - fastcnft.io
          </Typography>
        </Container>

      </ThemeProvider>
    </div>
  );
}

export default App;
